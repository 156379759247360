<template>
    <!-- 用户管理列表页面 -->
    <div id="userManageList" class="communal" ref="communal">
        <!-- 顶部固定 -->
        <div class="top-fixed" ref="topFixed">
            <div class="container">
                <el-row :gutter="24">
                    <el-col style="width:20%;position: relative;">
                        <span class="label">注册日期:</span>
                        <el-date-picker
                            v-model="form.dateTime"
                            type="daterange"
                            range-separator="-"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-dd"
                            :editable="false"
                            style="width:70%;"
                        ></el-date-picker>
                    </el-col>
                    <el-col style="width:20%">
                        <span class="label">常住地址:</span>
                        <el-select
                            v-model="form.area_id"
                            clearable
                            placeholder="请选择"
                            style="width:70%;"
                        >
                            <el-option
                                v-for="item in address"
                                :key="item.area_id"
                                :label="item.area_name"
                                :value="item.area_id"
                            ></el-option>
                        </el-select>
                    </el-col>
                    <el-col style="width:20%">
                        <span class="label">家庭医生:</span>
                        <el-select
                            v-model="form.doctor_info_id"
                            clearable
                            placeholder="请选择"
                            style="width:70%;"
                        >
                            <el-option
                                v-for="item in dlist"
                                :key="item.doctor_info_id"
                                :label="item.doctor_name"
                                :value="item.doctor_info_id"
                            ></el-option>
                        </el-select>
                    </el-col>
                    <el-col style="width:20%">
                        <span class="label" style="color:#fff">关键字查询:</span>
                        <el-input
                            style="width:70%;"
                            v-model="form.txt"
                            placeholder="请输入关键字查询"
                            @keyup.enter.native="onSubmit"
                        ></el-input>
                    </el-col>
                    <el-col style="width:20%" class="search-btn">
                        <div style="width:100%;display:inline-block;text-align: right;">
                            <el-button type="primary" plain @click="onSubmit">搜索</el-button>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div class="interval"></div>
        </div>
        <!-- 内容 -->
        <div class="content">
            <div class="container" :style="{height:contentHeight+'px'}">
                <el-table
                        ref="refTable"
                        :data="list"
                        :header-row-class-name="'table_header'"
                        :empty-text="tableTip"
                        style="width: 100%"
                        :height="contentHeight"
                        v-loading="loading"
                    >>
                        <el-table-column prop="user_id" label="ID"></el-table-column>
                        <el-table-column prop="name" label="昵称"></el-table-column>
                        <el-table-column prop="area_name" label="常住地址"></el-table-column>
                        <el-table-column prop="home_doctor" label="家庭医生" ></el-table-column>
                        <el-table-column prop="addtime" label="注册时间"></el-table-column>
                        <el-table-column prop="status" label="状态">
                            <template slot-scope="scope">
                                <span v-if="scope.row.status == '1'">正常</span>
                                <span v-if="scope.row.status == '2'">冻结</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="name" label="操作" fixed="right" width="200">
                            <template slot-scope="scope">
                                <el-button type="text" @click.stop="skipInfo(scope.row.user_id,3)" v-if="scope.row.status == '1'">查看</el-button>
                                <el-button type="text" @click.stop="freezing(scope.row.user_id,1)" v-if="scope.row.status == '1'">冻结</el-button>
                                <el-button type="text" @click.stop="freezing(scope.row.user_id,2)" v-if="scope.row.status == '2'">解冻</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
            </div>
        </div>
        <!-- 分页 100-->
        <div class="paging" ref="paging">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page="currentPage"
                :page-sizes="[10, 50, 100, 200]"
                :page-size="psize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totals"
            ></el-pagination>
        </div>

        <!-- 调用按钮操作性弹框 -->
        <operationBtn
            v-if="showHide"
            :issue="issue"
            :hintTxt="hintTxt"
            :showHide="showHide"
            :operationId="operationId"
            :operationPsotUrl="operationPsotUrl"
            :operationPsotName="operationPsotName"
            @childByOperationBtn="childByOperationBtn"
        ></operationBtn>
    </div>
</template>
<script>
import { RegistUserList } from "../../api/api";
import { FreezeO } from "../../api/api";
import { UnFreezeO } from "../../api/api";
import { UserAreaList } from "../../api/api";
import { HomeDoctorList } from "../../api/api";
import operationBtn from "../../components/operationBtn";
import { constants } from "crypto";
import { truncate } from 'fs';
export default {
    components: {
        operationBtn,
    },
    data() {
        return {
            topHeight: "", //顶部搜索的高度
            mainHeight: "", //获取当前容器的高度
            pagingHeight: "", //获取分页的高度
            contentHeight: 0, //内容的高度
            form: {
                dateTime: [], //日期
                area_id: "", //常住地址
                doctor_info_id:"",//家庭医生
                txt: "" //文字
            },
            address: [], //地址列表
            dlist:[],//医生列表
            list: [], //表格
            totals: null,
            pages: 1,
            psize: 10,
            currentPage: 1, //默认显示页
            tableTip: "系统正在检索中…",
            loading:false,

            // 操作性按钮弹框值   start
            issue: "", //问题   上面的文字
            hintTxt: "", //提示文本   下方的文字
            showHide: false, //弹框的隐藏显示
            operationId: "", //操作id
            operationPsotUrl: "", //操作接口名字
            operationPsotName:"",//接口键
            // 操作性按钮弹框值   end
        };
    },
    created() {
        this.getTags();
        this.getDoctor();
    },
    mounted() {
        var that = this;
        that.common.Count.pageResize(that);
        window.onresize = function temp() {
            that.common.Count.pageResize(that);
        };

        this.getUserList();
    },

    methods: {
        //获取用户列表
        getUserList() {
            var that = this;
            var db = {
                from_date:
                    this.form.dateTime != null ? this.form.dateTime[0] : "",
                to_date:
                    this.form.dateTime != null ? this.form.dateTime[1] : "",
                txt: this.form.txt,
                area_id: this.form.area_id, //常住地址
                doctor_info_id:this.form.doctor_info_id,//家庭医生
                pnums: this.psize,
                pages: this.pages
            };
            this.loading = true;
            this.$axios.post(RegistUserList, db).then(
                res => {
                    this.loading = false;
                    if (res.data.code == 1) {
                        if (res.data.data.length > 0) {
                            this.list = res.data.data;
                            this.totals = res.data.count;
                        } else {
                            this.list = [];
                            this.totals = 0;
                            this.tableTip = "暂无注册用户";
                        }
                    } else {
                        this.list = [];
                        this.totals = 0;
                        this.tableTip = "暂无注册用户";
                    }
                },
                err => {
                    this.loading = false;
                    this.list = [];
                    this.totals = 0;
                    this.tableTip = "暂无注册用户";
                }
            );
        },
        //获取标签
        getTags() {
            var that = this;

            this.$axios.post(UserAreaList, {}).then(
                res => {
                    if (res.data.code == 1) {
                        if (
                            res.data.data != undefined &&
                            res.data.data.length > 0
                        ) {
                            this.address = res.data.data;
                        } else {
                            this.address = [];
                        }
                    } else {
                        this.address = [];
                    }
                },
                err => {
                    this.address = [];
                }
            );
        },
        getDoctor(){
            var that = this;
            this.$axios.post(HomeDoctorList, {}).then(
                res => {
                    if (res.data.code == 1) {
                        if (
                            res.data.data != undefined &&
                            res.data.data.length > 0
                        ) {
                            this.dlist = res.data.data;
                        } else {
                            this.dlist = [];
                        }
                    } else {
                        this.dlist = [];
                    }
                },
                err => {
                    this.dlist = [];
                }
            );
        },
        //查看跳转
        skipInfo(id) {
            this.$router.push({
                path: "/dataCenter/viewInfo",
                query: { id: id }
            });
        },
        //冻结，解冻，退款，通过提现审核
        freezing(id, type) {
            this.operationId = id;
            this.operationPsotName = 'user_id';
            this.showHide = !this.showHide;
            if(type == 1) {
                //冻结
                this.issue = '确定冻结该用户？';
                this.hintTxt =  "冻结后该用户将不可登录用户端";
                this.operationPsotUrl = FreezeO;

            } else if (type == 2) {
                //解冻
                this.issue = '确定冻解冻该用户？';
                this.hintTxt =  "解冻后该用户可继续使用用户端相关功能";
                this.operationPsotUrl = UnFreezeO;
            };
        },
        //弹框返回
        childByOperationBtn: function(showHide, dataCode) {
            //接收按钮式操作子组件传值
            this.showHide = showHide;
            if (dataCode == 1) {
                //接口调用成功，调用其他接口
                this.getUserList();
            }
        },
        //搜索
        onSubmit() {
            this.pages = 1;
            this.getUserList();
        },
        handleCurrentChange(val) {
            this.pages = val;
            this.getUserList();
        },
        handleSizeChange(val) {
            this.psize = val;
            this.getUserList();
        },
    }
};
</script>
<style lang="scss">
.label {
    font-size: 14px;
    color: #666666;
    margin-right: 10px;
}
.search-btn {
    position: absolute;
    right: 0px;
    bottom: 0px;
}
.table {
    height: calc(100vh - 167px - 100px - 40px - 20px);
    .props-rows {
        padding: 10px 0px 10px 45px;
        .title {
            color: #999999;
            margin-right: 20px;
        }
    }
}
.rows-tag {
    color: #666666;
    margin-right: 20px;
}
</style>
